import md5 from './md5'
const SM4 = require("gm-crypt").sm4;

export const gmcryptSm4 = (pwd, key) => {
  const sm4Config = key
    ? ({
      key: md5(key, 16),
      iv: md5(key, 16)
    })
    : ({
      key: md5(pwd, 32).substring(16),
      iv: md5(pwd, 32).substring(16)
    })
  const sm4 = new SM4(sm4Config);
  return sm4.encrypt(pwd.trim());
}
