/**
 * 此处可直接引用自己项目封装好的 axios 配合后端联调
 */

import http from '@/until/http' //组件内部封装的axios

export const sendMsg = async (params, type) => {
	return http({
		url: `/oauth2/verify-code/${type}`,
		method: 'get',
		params: params,
	})
}

export const forgetPassword = async (params) => {
	return http({
		url: `/oauth2/login/phone/{type}`,
		method: 'get',
		params: params,
	})
}

export const getPhoneAccount = async (params) => {
	return http({
		url: `/oauth2/phone-user`,
		method: 'get',
		params: params,
	})
}

// /oauth2/reset-password

export const restPassword = async (data) => {
	return http({
		url: `/oauth2/reset-password`,
		method: 'post',
		data,
	})
}

export const loginByPhone = async (data) => {
	return http({
		url: `/oauth2/login/phone/phone`,
		method: 'post',
		data,
	})
}

export const loginByPhoneLogin = async (data) => {
	return http({
    url: '/oauth2/login/phone-redirect',
		method: 'post',
		data,
	})
}

//主账号登录
export const mainLogin = async (data) => {
	return http({
		url: '/oauth2/login/main-user/front ',
		method: 'post',
		data,
	})
}

//子账号登录
export const subLogin = async (data) => {
	return http({
		url: '/oauth2/login/sub-user/front',
		method: 'post',
		data,
	})
}
//刷新路径
export const refresh = async (data) => {
	return http({
		url: '/oauth2/refresh-authorize?clientId='+data.clientId+'&redirectUri='+data.redirectUri,
		method: 'get',
	})
}