/**
 * 此处可直接引用自己项目封装好的 axios 配合后端联调
 */

import http from '../../../until/http' //组件内部封装的axios

//获取验证图片  以及token
export function reqGet(data) {
	return http({
		url: '/oauth2/login/captcha/get',
		method: 'post',
		data,
	})
}

//滑动或者点选验证
export function reqCheck(data) {
	return http({
		url: '/oauth2/login/captcha/first-verify ',
		method: 'post',
		data,
	})
}
