import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../view/login.vue'
import Rest from '../view/restSuccess.vue'
Vue.use(VueRouter)

const Router = new VueRouter({
	routes: [
		{
			path: '/',
			name: '登录',
			component: Login,
			meta: {
				title: '登录',
			},
		},
		{
			path: '/rest',
			name: '重置成功',
			component: Rest,
			meta: {
				title: '重置成功',
			},
		},
	],
})

Router.afterEach((to, from, next) => {
	document.title = to.meta.title
})
export default Router
