<template>
	<div>
		<Form
			:model="mainFrom"
			ref="mainFrom"
			label-position="right"
			:label-width="0"
			:rules="ruleValidate"
			@submit.prevent=""
			@keyup.native.enter="sumbit('mainFrom')"
		>
			<FormItem label="" prop="user">
				<Input v-model="mainFrom.user" placeholder="管理员登录账号">
					<i slot="prefix" class="iconfont icon-ziyonghuzhanghaobeifen"></i>
				</Input>
			</FormItem>
			<FormItem label="" prop="password">
				<Input
					v-model="mainFrom.password"
					placeholder="密码"
					type="password"
					@keyup.native.enter="sumbit('mainFrom')"
				>
					<i slot="prefix" class="iconfont icon-a-mimabeifen2"></i
				></Input>
			</FormItem>
			<div class="forget" style="margin-bottom: 10px">
				<span class="hand" @click="$emit('changeSelectVal', 2)"
					>手机验证码登录</span
				>
				<span class="hand" @click="$emit('changeSelectVal', 3)">忘记密码</span>
			</div>
			<div class="login_submit">
				<Button type="primary" @click="sumbit('mainFrom')">登录</Button>
			</div>
		</Form>
	</div>
</template>

<script>
	import * as apiServices from '@/api'
	import { gmcryptSm4 } from '@/until/core.js'
	export default {
		props: {
			browerData: {
				type: Object,
				required: true,
			},
			captchaVerification: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				mainFrom: {
					user: '',
					password: '',
				},
				ruleValidate: {
					user: [
						{
							required: true,
							message: '账号不能为空',
							trigger: 'change',
						},
					],
					password: [
						{
							required: true,
							message: '密码不能为空',
							trigger: 'change',
						},
					],
				},
			}
		},
		methods: {
			refresh(){
				let clientId = this.browerData.clientId
				let redirectUri = this.browerData.redirectUri
				apiServices.refresh({
					clientId:clientId,
					redirectUri:redirectUri
				}).then(res => {
					window.location = res.data	
				}).catch(err => {
				})
			},
			async sumbit(name) {
				this.$refs[name].validate(async (valid) => {
					if (valid) {
						this.$emit('showVerify')
					}
				})
			},
			async success(captchaVerification) {
				let data = {
					...this.browerData,
					account: this.mainFrom.user,
					password: gmcryptSm4(this.mainFrom.password),
					captchaVerification: captchaVerification,
				}

        // 子用户链接跳转登录中传递的id和page字段
        data.id && delete data.id;
        data.page && delete data.page;
				apiServices.mainLogin(data).then(res => {
					window.location = res.data
				}).catch(err => {
					if(err.errorcode==2000){
						this.refresh()
						//登录超时需要刷新
						return
					}
				})
				// let res = await apiServices.mainLogin(data)
				// window.location = res.data
			},
		},
	}
</script>
<style lang="less"></style>
