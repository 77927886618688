<template>
	<div>
		<Form
			:model="subFrom"
			:rules="ruleValidate"
			ref="subFrom"
			label-position="right"
			:label-width="0"
			@submit.prevent=""
			@keyup.native.enter="sumbit('subFrom')"
		>
		<!-- 测试放开   打包需要注释 -->
			<!-- <FormItem label="" prop="id">
				<Input v-model="subFrom.id" placeholder="主账户ID">
					<i
						slot="prefix"
						class="iconfont icon-zhuzhanghaoidbeifen"
						style="font-size: 15px"
					></i>
				</Input>
			</FormItem> -->

			
			<FormItem label="" prop="user">
				<Input v-model="subFrom.user" placeholder="登录账号">
					<i slot="prefix" class="iconfont icon-ziyonghuzhanghaobeifen"></i>
				</Input>
			</FormItem>
			<FormItem label="" prop="password">
				<Input
					v-model="subFrom.password"
					placeholder="密码"
					type="password"
					@keyup.enter="sumbit('subFrom')"
				>
					<i slot="prefix" class="iconfont icon-a-mimabeifen2"></i>
				</Input>
			</FormItem>
			<div class="forget" style="margin-bottom: 10px">
				<span class="hand" @click="$emit('changeSelectVal', 2)"
					>手机验证码登录</span
				>
				<span class="hand" @click="$emit('changeSelectVal', 3)">忘记密码</span>
			</div>
			<div class="login_submit">
				<Button type="primary" @click="sumbit('subFrom')">登录</Button>
			</div>
		</Form>
	</div>
</template>

<script>
	import * as apiServices from '@/api'
	import { gmcryptSm4 } from '@/until/core.js'
	export default {
		props: {
			browerData: {
				type: Object,
				required: true,
			},
			captchaVerification: {
				type: String,
				required: true,
			},
		},
    watch: {
      browerData(newVal){
        if(newVal && newVal.id) this.subFrom.id = newVal.id
      }
    },
		data() {
			return {
				subFrom: {
					// id:'',
					id: '1260463067108610048',
					user: '',
					password: '',
				},
				ruleValidate: {
					id: [
						{
							required: true,
							message: '主账户ID不能为空',
							trigger: 'change',
						},
						{
							pattern: /^[0-9]*[1-9][0-9]*$/,
							message: '必须为正整数',
							trigger: 'change',
						},
					],
					user: [
						{
							required: true,
							message: '账号不能为空',
							trigger: 'change',
						},
					],
					password: [
						{
							required: true,
							message: '密码不能为空',
							trigger: 'change',
						},
					],
				},
			}
		},
		methods: {
			refresh(){
				let clientId = this.browerData.clientId
				let redirectUri = this.browerData.redirectUri
				apiServices.refresh({
					clientId:clientId,
					redirectUri:redirectUri
				}).then(res => {
					window.location = res.data	
				}).catch(err => {
				})
			},
			sumbit(name) {
				this.$refs[name].validate((valid) => {
					if (valid) {
						this.$emit('showVerify')
					}
				})
			},
			async success(captchaVerification) {
				let data = {
					...this.browerData,
					account: this.subFrom.user,
					password: gmcryptSm4(this.subFrom.password),
					mainUserId: this.subFrom.id,
					captchaVerification: captchaVerification,
				}
        // 子用户链接跳转登录的方式暂未开放，传递的page和id字段暂时移除
        data.id && delete data.id;
        data.page && delete data.page;
				apiServices.subLogin(data).then(res => {
					window.location = res.data
				}).catch(err => {
					if(err.errorcode==2000){
						this.refresh()
						//登录超时需要刷新
						return
					}
				})
				// let res = await apiServices.subLogin(data)

				// window.location = res.data
			},
		},
	}
</script>
