import Vue from 'vue'
import App from './App.vue'
import ViewUI from 'view-design'
import Router from './router'
import 'view-design/dist/styles/iview.css'
import '@/assets/icons/iconfont.css'

Vue.use(ViewUI)

Vue.config.productionTip = false

new Vue({
	render: (h) => h(App),
	router: Router,
}).$mount('#app')
